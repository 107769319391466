:root{
/* Evy: */
    --bg: #e0e0e0;

    --title-bg-color: rgb(240, 234, 184);
    --title-bg-color-rgb: 240, 234, 184;

    --layout-color: rgb(167, 220, 183);
    --layout-color-rgb: 167, 220, 183;
    --layout-color-hsl: hsl(138, 43%, 76%);

/*    --bg: #e0e0e0;

    --title-bg-color: #eeda83;

    --layout-color: #1B9D8E;
    --layout-color-rgb: 27, 157, 142;
    
    --highlight-color: #ED6A5A;

*/


    --content-width: 90vw;
    --content-bg: white;

    --content-margin-top: min(4vh, 5vw);
    --content-margin-bottom: min(5vh, 6vw);
    
    
    --medium-font-size: max(1.0vw, 14px);
    --large-font-size: max(1.2vw, 16px);
    --larger-font-size: max(1.4vw, 18px);

    --title-font-size: max(2.4vw, 35px);
    --subtitle-font-size: max(1.5vw, 24px);
}


*{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}


body{
    margin: 0px;
    background-color: var(--footer-bg);
}

#root{
    position: relative;
    padding-bottom: var(--footer-height);
    background-color: var(--bg);
}


.colorizable[color="layout"]{
    color: var(--layout-color);
}
.colorizable[color="highlight"]{
    color: var(--highlight-color);
}



.navigAnim{
    animation-duration: 500ms;
    animation-name: ctrl;
    animation-iteration-count: infinite;
}

@keyframes ctrl {
    0% { --bg: #ff0000; }
    4% { --bg: #d82000; }
    8% { --bg: #c04000; }
    12% { --bg: #a86000; }
    16% { --bg: #808000; }
    20% { --bg: #60a800; }
    24% { --bg: #40c000; }
    28% { --bg: #20d800; }
    33% { --bg: #00ff00; }
    37% { --bg: #00d820; }
    41% { --bg: #00c040; }
    45% { --bg: #00a860; }
    49% { --bg: #008080; }
    53% { --bg: #0060a8; }
    57% { --bg: #0040c0; }
    63% { --bg: #0020d8; }
    66% { --bg: #0000ff; }
    70% { --bg: #2000d8; }
    74% { --bg: #4000c0; }
    78% { --bg: #6000a8; }
    82% { --bg: #800080; }
    86% { --bg: #a80060; }
    90% { --bg: #c00040; }
    95% { --bg: #d80020; }
    100% { --bg: #ff0000; }
}


@media (max-width: 550px){
    :root{
        --title-font-size: 35px;
        --subtitle-font-size: 24px;
    }
}


@media (max-width: 350px){
    :root{
        --title-font-size: 23px;
    }
}

