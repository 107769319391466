:root{
    --content-border-width: 20px;
}


.content-field{
    background-color: var(--content-bg);

    min-height: 50vh;

    padding: 15px 0;

    border-width: var(--content-border-width);
    border-style: solid;
    border-radius: 15px;
    border-color: var(--content-bg);
}



.flex-row{
    display: flex;
    flex-direction: row;
}
.flex-column{
    display: flex;
    flex-direction: column;
}



.split-lines{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.split-lines .line{
    width: 30%;

    background-color: var(--layout-color);

    height: 3px;

    margin: 0;
}
.split-lines .line[length=medium]{
    width: 50%;
}
.split-lines .line[length=large]{
    width: 75%;
}
.split-lines .line[width=thin]{
    background-color: var(--layout-color);

    height: 2px;
}