:root{
    --nav-fg: black;
    --nav-bg-alpha: 1;

    --nav-bg-rgb: 235, 235, 235;
    --nav-bg: rgba(var(--nav-bg-rgb), var(--nav-bg-alpha));
    --nav-bg-click-hover: rgba(var(--nav-bg-rgb), 1);

    --nav-height: max(min(min(75px, 7vh), 10vw), 40px);
    --nav-padding-x: 3vw;

    --nav-font-size: max(min(calc(max(min(75px, 8vh), 45px) * 0.3), 2.2vw), 16px);
    --nav-dropdown-font-size: 25px;

    --navBtn-hovAnim-dur: 0.4s;
}


.navbar{
    background-color: var(--nav-bg);

    position: sticky;
    top: 0;
    height: var(--nav-height);

    padding: 0 var(--nav-padding-x);
    margin: 0;

    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    
    z-index: 10;
}

.navbar *{
    color: var(--nav-fg);
    background-color: rgba(0, 0, 0, 0);
    white-space: nowrap;
}


.navbar .home-btn{
    height: 100%;
    overflow: visible;
}

.home-btn .btn-h{
    background-color: rgba(0, 0, 0, 0);
    height: 100%;
    
    padding: 6px;
    border: 0;
    border-radius: 3px;
}

.btn-h .image{
    height: 100%;

    cursor: pointer;
}


.navbar .intern-title{
    color: #EE1100;
    font-size: var(--nav-font-size);
    text-decoration: underline;
}


.navbar .line-nav{
    height: 100%;

    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
}



.page-loaded{
    font-weight: 700;
}



.navbar .btn-nav{
    height: 100%;
}

.btn-nav .btn-n{
    font-size: var(--nav-font-size);

    color: var(--nav-fg);
    text-align: center;

    height: 100%;
    padding-right: max(0.7vw, 8px);
    padding-left: max(0.7vw, 8px);
    border: 0;
}

.btn-n .text{
    margin: 0;
}



.navbar .dropdown{
    height: 100%;
    
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
}
.dropdown .btn-d{
    width: 100%;
    height: 100%;
    border: 0;
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    cursor: pointer;
}

.btn-d:hover{
    background-color: var(--nav-bg-click-hover);
}


.btn-d .icon-d{
    height: calc(var(--nav-height) - 40%);

    border: 0;

    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-d .img{
    height: 80%;
}

.dropdown-down-arrow{
    height: 9px;

    margin-right: 11px;
}


.btn-d .text-d{
    font-size: var(--nav-font-size);

    color: var(--nav-fg);
    text-align: center;

    width: 100%;
    height: 100%;

    padding-right: 0.7vw;
    padding-left: 0.7vw;
    border: 0;
    margin: 0;
    
    cursor: inherit;
}




.dropdown .menu-d{
    background-color: rgba(var(--nav-bg-rgb), 0.9);

    padding-top: 6px;

    width: max-content;

    display: flex;
    flex-direction: column;

    position: fixed;
    top: var(--nav-height);
    transform: translateX(-50%);
    /*right: calc(var(--nav-padding-x) * -1);*/

    transition-duration: var(--navBtn-hovAnim-dur);
}
.menu-d[animation="0"] {
    animation: dropdown-menu-popup 0.5s forwards;
}
.menu-d[animation="1"] {
    animation: dropdown-menu-popup 0.5s backwards;
}
@keyframes dropdown-menu-popup {
    0% { opacity: 0; }
    100% { opacity: 1; }
}



.dropdown-nested{
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}


.menu-d .item-dm{
    text-align: left;
}
.item-dm .btn-dmi, .dropdown-nested .item{
    font-size: var(--nav-font-size);
    white-space: break-spaces;

    color: var(--nav-fg);
    text-align: left;

    height: 100%;
    padding: 0.7vh 0.7vw;
    border: 0;
}



.btn-clickable{
    width: 100%;

    transition-duration: var(--navBtn-hovAnim-dur);
    cursor: pointer;
}
.btn-clickable:hover{
    background-color: var(--nav-bg-click-hover);
}





/*------------ NARROW SCREEN ------------*/
@media (max-width: 675px) {
    .btn-h .image{
        content: url("../../../public/Icons/fbg-bremen_tabIcon-black.png");
    }
}

@media (max-width: 550px) {
    .btn-h .image{
        content: url("../../../public/Icons/fbg-bremen_icon-black-fg.png");
    }

    .dropdown .menu-d{
        top: var(--nav-height);
        right: 0;
        transform: translateX(var(--nav-padding-x));
    }
    
    .menu-d .item-dm, .menu-d .dropdown-nested{
        padding: 6px 20px;
        padding-right: 12vw;
        margin: 1px 0;
    }
    .item-dm .btn-dmi{
        font-size: var(--nav-dropdown-font-size);
    }
    .dropdown-nested .item{
        font-size: var(--nav-dropdown-font-size);
        
        padding-right: 12px;
    }
}


@media (hover: none) {
    .btn-clickable:hover{
        background-color: var(--nav-bg);
        transform: scale(1);
    }
}