



#homepage .top_verse{
    background-color: rgba(var(--layout-color-rgb), 0.4);

    padding: 0.1vh 12%;

    margin: 6vh calc(-1 * var(--content-border-width));
    margin-top: 2vh;
}

.top_verse p{
    font-family: Georgia, 'Times New Roman', Times, serif;
    text-align: center;
    margin: min(4vh, 5vw) 0;
}

.top_verse .text{
    font-size: max(2.3vw, 24px);

    margin-bottom: 0;
}
.top_verse .passage{
    font-size: max(1.9vw, 20px);
    text-align: end;

    margin-top: 0;
    margin-right: 2vw;
}

.top_verse .passage::before{
    content: "- ";
}



#homepage .event{
    background-color: rgba(var(--layout-color-rgb), 0.3);

    height: fit-content;

    padding-bottom: 2.5vh;

    border-width: 3px;
    border-radius: 10px;
    border-color: var(--layout-color);
    border-style: solid;

    margin: 0 10vw;
    margin-bottom: 6vh;

    overflow: auto;
}

.event .event-header{
    margin-left: 5vw;
}

.event-header .title{
    font-size: var(--large-font-size);

    width: 25%;

    padding: 0 1vw;
    border-radius: 4px;
    margin-bottom: 0;
}

.event-header .date{
    font-size: var(--medium-font-size);
    font-weight: 600;

    width: fit-content;

    margin: 0 0;
    margin-left: 33vw;
}

.event .event-content{
    height: fit-content;

    margin: 0 2vw;

    display: flex;
    flex-direction: row;
}

.event-content .image{
    width: max(24vw, 100px);
    height: 100%;

    border-radius: 2px;

    margin-right: 4vw;

    shape-outside: margin-box;
}

.event-content .event-text{
    height: initial;

    padding-bottom: 1vh;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.event-text .text{
    font-size: var(--medium-font-size);

    margin-top: 2vh;
    margin-bottom: auto;
}

.event-text .where{
    font-size: var(--medium-font-size);
    text-align: center;

    margin-bottom: 0;
    margin-top: auto;
}

.where .place{
    font-weight: 600;
    margin-left: 1vw;
}



.zeitplan.homepage{
    width: 100%;
}

#homepage .gottesdienste-preview{
    padding: 0 20%;
    margin-bottom: 5vh;

    display: flex;
    flex-direction: column;
    justify-content: center;   
    align-items: center; 
}

.gottesdienste-preview .invitation{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.invitation .text{
    font-size: var(--larger-font-size);
    text-align: center;
    
    margin: 4vh 2vw;
}

.gottesdienste-preview .btn-link{
    width: fit-content;

    padding: 0.7vh 0.6vw;

    border-width: 3px;
    border-color: var(--layout-color);
    border-radius: 9px;
    border-style: dashed;

    margin-left: 1vw;
}


#homepage .solas-panel{

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: wrap;
}

.solas-panel .sola{
    width: max(24vw, 400px);

    margin: 0 2.5vw;
    margin-bottom: 7vh;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.sola .flip-card{
    background-color: transparent;

    width: 85%;
    height: fit-content;

    margin-bottom: 1vh;

    perspective: 1000px;
}
.flip-card .sola-content{
    background-color: rgba(var(--layout-color-rgb), 0.2);

    position: relative;
    
    min-height: fit-content;
    height: 17vh;

    padding: 2.5vh 0;

    border-color: var(--layout-color);
    border-width: 4px;
    border-style: solid;
    border-radius: 3px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
    transition: transform 0.6s;
    transform-style: preserve-3d;
}
.sola .front, .sola .back{
    position: absolute;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.sola .front{
    height: 80%;
}
.sola .icon{
    height: 80%;
    max-width: 90%;

    transform-style: preserve-3d;
}
.sola .flip-icon{
    width: max(1.6vw, 16px);
    height: auto;
    position: absolute;
    right: 0.3vw;
    bottom: 0.5vh;
}
.sola .verse{
    width: 90%;
    height: fit-content;

    display: flex;
    flex-direction: column;
    justify-content: center;
}
.verse .text{
    font-size: max(min(3vw, 1.8vh), 12px);
    text-align: center;

    margin: 0;
}
.verse .passage{
    font-size: max(min(2.8vw, 1.7vh), 10px);
    text-align: center;

    margin: 0;
    margin-top: 1.7vh;
}

.sola .topic{
    background-color: var(--title-bg-color);

    width: 100%;

    padding: 0.6vh 0;

    border-radius: 3px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.topic p{
    margin: 0.3vh 0;
}

.topic .title{
    font-size: max(min(3.6vw, 2.4vh), 15px);
    text-align: center;
}

.topic .subtitle{
    font-size: max(min(3.2vw, 2vh), 13px);
    text-align: center;

    margin: 0 auto;
    padding: 0 10px;

    width: fit-content;
    border-width: 0;
    border-top: 1px;
    border-color: black;
    border-style: solid;
}



.btn-link button{
    background-color: transparent;

    width: max-content;

    border: 0;

    cursor: pointer;
}

.btn-link p{
    font-size: var(--medium-font-size);
    text-decoration: underline;
}


@media (max-width: 1000px) {
    .pagePreview{
        width: 100%;

        margin-top: 1vh;
        margin-bottom: 1vh;
    }
}

@media (max-width: 750px){
    #homepage .predigten-preview{
        flex-direction: column;
        align-items: center;
    }

    .predigten-preview .video{
        width: 60vw;
    
        margin: 0;
    }
    
    .video p{
        font-size: var(--large-font-size);
    }


    .lehre .btn-link{
        margin-top: 3vh;
    }
}

@media (max-width: 600px){
    #homepage .gottesdienste-preview{
        padding: 0 10%;
    }

    #homepage .event{
        padding-bottom: 1vh;
        margin: 0 5vw;
    }
    
    .predigten-preview .video{
        width: 70vw;
    }
}

@media (max-width: 550px){
    #homepage .top_verse{
        padding: 0.1vh 6%;
    }

    #homepage .gottesdienste-preview{
        padding: 0;
    }
    
    #homepage .event{
        margin: 0;
    }
}
