:root{
    --toggle-width: max(2.6vw, 38px);
    --toggle-height: max(1.2vw, 18px);
    --toggle-padding: 2px;
    --slider-x-offset: 1px;
}

.toggle{
    background-color: rgb(168, 168, 168);
    
    width: var(--toggle-width);
    height: var(--toggle-height);

    border-radius: var(--toggle-height);

    position: relative;

    transition-property: background-color;
    transition-duration: 400ms;
    transition-timing-function: ease;
}
.toggle[ischecked=true]{
    background-color: rgb(31, 140, 230);
}

.toggle .slider{
    background-color: white;

    width: calc(var(--toggle-height) - 2 * var(--toggle-padding));
    height: calc(var(--toggle-height) - 2 * var(--toggle-padding));

    border-width: 2px;
    border-radius: var(--toggle-height);
    border-color: black;
    border-style: solid;

    position: absolute;
    box-sizing: border-box;
    top: var(--toggle-padding);
    left: calc(var(--toggle-padding) - var(--slider-x-offset));

    transition-property: transform;
    transition-duration: inherit;
    transition-timing-function: inherit;
}
.toggle .slider[ischecked=true]{
    transform: translateX(calc(var(--toggle-width) - 100% - 2 * var(--toggle-padding) + 2 * var(--slider-x-offset)));
}



@media (max-width: 1200px) {
    .toggle .slider{
        border-width: 1px;
    }
}
