


#gottesdienste .anfahrt{
    margin-left: 10%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

#gottesdienste .address-map-shell{
    display: flex;
    flex-direction: row;
}

.maps{
    background-color: rgba(var(--layout-color-rgb), 0.2);

    width: 45vw;

    padding: 3vh 1.5vw;
    border-radius: 7px;
    border-width: 2px;
    border-color: var(--layout-color);
    border-style: solid;
}
.maps .map{
    width: 100%;
    aspect-ratio: 1.602;

    border-radius: 6px;
    border-style: hidden;
}
.maps .btn{
    font-size: max(1vw, 11px);
    width: max-content;
    margin: 0;
    margin-top: 1vh;
}

.address{
    font-size: var(--medium-font-size);

    padding-top: 2vh;
    margin-left: 2vw;
}
.address .paragraph{
    margin: min(1vh, 1.6vw) 0;
}


.wegbeschreibung{
    font-size: var(--medium-font-size);

    margin: 1vh 2vw;
}
.wegbeschreibung .image{
    width: max(30vw, 350px);
    aspect-ratio: 1.5;

    margin-left: 5vw;

    border-width: 1px;
    border-style: solid;
    border-color: black;
    border-radius: 8px;
}
.wegbeschreibung .paragraph{
    margin: 2vh 0;
}


.zeitplan.godi-page{
    padding: 0 15%;
    margin-bottom: 6vh;
}



@media (max-width: 600px){
    .zeitplan.godi-page{
        padding: 0 10%;
    }

    .wegbeschreibung .image{
        width: 60vw;
    
        margin-left: 3vw;
    }
}

@media (max-width: 550px){
    .zeitplan.godi-page{
        padding: 0;
    }

    #gottesdienste .anfahrt{
        margin: 0 2%;
    }

    #gottesdienste .address-map-shell{
        flex-direction: column;
    }
    .maps{
        width: 100%;
        padding: 0;
        border-style: hidden;
    }
    .image-map-area{
        width: 100%;
        box-sizing: border-box;
    
        padding: 1vh 2vw;
        border-radius: 7px;
        border-width: 2px;
        border-color: var(--layout-color);
        border-style: solid;
    }
    .maps .map{
        width: 100%;
        box-sizing: border-box;
    
        border-radius: 5px;
        border-style: hidden;
    }
    .maps .btn{
        font-size: max(1vw, 11px);
        width: max-content;
        margin: 0;
        margin-top: 1vh;
    }
    
    .address{
        margin-left: 10%;
    }

    .wegbeschreibung .image{
        width: 65vw;
    
        margin-left: 3vw;
    }
}