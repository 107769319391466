


.title-component{
    background-color: var(--title-bg-color);

    font-size: var(--title-font-size);
    text-align: start;

    padding: 5vh 3vw;
    margin-bottom: var(--content-margin-top);
}
.title-component .topLine{
    background-color: black;
    width: 75px;
    height: 3px;
}
.title-component .title{
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    font-weight: 500;

    background-color: var(--title-bg-color);

    margin: 0px;
}


.subtitle-component{
    text-align: center;
    font-size: var(--subtitle-font-size);
    font-weight: 600;

    margin-top: 3vh;
    margin-bottom: 2vh;
}
.subtitle-component p{
    margin: 0;
}

.subtitle-component[underline="true"]{
    text-decoration: underline;
}