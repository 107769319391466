

.data-loading {
    text-align: center;

    margin: 0 10%;
}

.data-loading .loading{
    font-size: max(1.5vw, 16px);

    margin-bottom: 5vh;
}

.data-loading .load-error{
    font-size: max(1vw, 11px);
    color: rgb(210, 0, 50);
}

.data-loading .thanks{
    font-size: max(1.3vw, 14px);
    
    margin-top: 6vh;
}


.dat-scroll-button{
    font-size: inherit;
    background: none;
    border: none;
    padding: 0;
    color: rgb(0, 89, 145);
    text-decoration: underline;
    cursor: pointer;
}