

#datenschutz .nav-table{
    --datenschutz-nav-table-width: 35%;

    width: var(--datenschutz-nav-table-width);

    margin: 0 calc((100% - var(--datenschutz-nav-table-width)) / 2);
    margin-top: 4vh;

    border-collapse: collapse;
}
.nav-table .item{
    width: 100%;

    border-width: 2px;
    border-color: grey;
    border-style: solid none solid none;
    
    margin: -2px 0;
}
.item button{
    font-size: max(1.2vw, 13px);
    text-align: start;
    
    background-color: transparent;
    color: hsl(138, 75%, 61%);

    width: 100%;

    padding: max(0.7vw, 4px) 15%;

    border: none;

    cursor: pointer;
}


#datenschutz .scroll-to{
    scroll-margin: calc(var(--nav-height) + 20px);
}


#datenschutz .settings-interface{
    --datenschutz-settings-width: 40%;

    box-sizing: border-box;
    width: var(--datenschutz-settings-width);

    padding: 0.1vh 3vw;
    padding-right: 5vw;

    margin: 3vh calc((100% - var(--datenschutz-settings-width)) / 2);
    margin-bottom: 4vw;

    border-width: 3px;
    border-radius: 6px;
    border-color: var(--layout-color);
    border-style: solid;
}

.settings-interface .setting{
    margin: max(0.4vh, 0.5vw) 0;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.setting .title{
    font-size: var(--medium-font-size);

    margin: 0;
}


#datenschutz .paragraph {
    margin: 0vh 5%;
    margin-top: max(5vh, 5vw);
}

.paragraph .text{
    font-size: var(--medium-font-size);
    line-height: 150%;

    white-space: pre-wrap;

    margin-top: max(2vh, 2vw);
}



@media (max-width: 1000px) {
    #datenschutz .nav-table{
        --datenschutz-nav-table-width: 45%;
    }
    #datenschutz .settings-interface{
        --datenschutz-settings-width: 60%;
    }
}

@media (max-width: 650px) {
    #datenschutz .nav-table{
        --datenschutz-nav-table-width: 60%;
    }
    #datenschutz .settings-interface{
        --datenschutz-settings-width: 80%;
    }
}

@media (max-width: 550px) {
    #datenschutz .nav-table{
        --datenschutz-nav-table-width: 70%;
    }
    #datenschutz .settings-interface{
        --datenschutz-settings-width: 90%;
        
        padding: 0.5vh 5vw;
    
        padding-right: 8vw;
    }
}
