:root {
    --form-font-size: max(1.2vw, 15px);
}


#kontakt .content-field{
    padding-left: 2vw;
    padding-right: 2vw;
}


#kontakt .clear-form{
    display: flex;
    justify-content: flex-end;
}

.clear-form button{
    font-size: var(--form-font-size);

    width: 8%;
    min-width: 75px;

    padding: 0.3vh 0.8vw;
}


#kontakt .form{
    font-size: var(--form-font-size);

    margin: 1vh 0;
    margin-bottom: 2vh;

    display: flex;
    flex-direction: column;
}

.form .input{
    margin: 0.7vh 0;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}
.input input, .input textarea{
    background-color: #F4F4F4;
    
    border-color: grey;
    border-radius: 3px;
    border-width: 1px;
    border-style: solid;
}

.form label{
    width: 17%;
    min-width: 80px;

    break-before: always;
}


.form .addressee{
    font-size: var(--form-font-size);
    
    width: 15%;
    min-width: 125px;

    padding: 0.3vh 0.2vw;
}


.form .name{
    font-size: var(--form-font-size);
    
    width: 20%;
    min-width: 160px;

    padding: 0.3vh 0.2vw;
}


.form .sender{
    font-size: var(--form-font-size);
    
    width: 30%;
    min-width: 215px;

    padding: 0.3vh 0.2vw;
}


.form .subject{
    font-size: var(--form-font-size);
    
    width: 67%;
    min-width: 240px;

    padding: 0.3vh 0.2vw;
}


.form .message{
    font-size: var(--form-font-size);

    width: 80%;
    min-width: 5vw;
    max-width: 100%;

    height: 25vh;
    min-height: 1.3vw;

    padding: 0.3vh 0.2vw;
}


.form .send-browser-data{
    width: max(1vw, 15px);
    height: max(1vw, 15px);

    margin-top: auto;
    margin-left: 5vw;
}
.form .send-browser-data-label{
    font-size: var(--form-font-size);

    width: max-content;
    max-width: 55%;

    margin-top: 2vh;
    margin-left: 5vw;
}


.form .datenschutz{
    font-size: var(--medium-font-size);

    margin-top: 4vh;
    margin-left: 20%;
    margin-right: 30%;
}


.form .submit{
    font-size: var(--form-font-size);

    width: 12%;
    min-width: fit-content;

    padding: 3px 15px;

    margin: 0 10%;
    margin-left: 30%;
    margin-top: 1vh;
}


#kontakt .send-successful{
    color: rgb(10, 210, 25);
    font-size: var(--large-font-size);
    
    padding-left: 4vw;
    margin-bottom: 3vh;
}


#kontakt .info-text{
    font-size: var(--medium-font-size);

    margin: 0vh 10%;
    margin-top: 1.4vh;
}



@media (max-width: 700px){
    .form .datenschutz{
        margin-left: 12%;
        margin-right: 20%;
    }
}

@media (max-width: 550px){
    .form .name{
        width: 45%;
        min-width: 0;
    }

    .form .subject{
        width: 75%;
        min-width: 0;
    }

    .form .message{
        width: 100%;
    }


    .form .datenschutz{
        margin-left: 10%;
        margin-right: 15%;
    }


    #kontakt .info-text{
        margin: 0.1vh 5%;
    }
}

@media (max-width: 425px){
    #kontakt .form{
        margin-top: 1vh;
        row-gap: 0;
    }

    .form label{
        margin-bottom: 0.2vh;
    }

    .form .name{
        width: 75%;
        min-width: 0;
    }

    .form .sender{
        width: 90%;
        min-width: 0;
    }

    .form .subject{
        width: 100%;
        min-width: 0;
    }
    
    .form .message{
        width: 100%;
        min-width: 0;
    }


    .form .datenschutz{
        margin-left: 0;
        margin-right: 0;
    }
}
