

#impressum .report{
    margin: 5vh 15%;
}

.report p{
    margin: 0;
}

.report .entry{
    margin-top: 3vh;
}

.entry .label{
    font-size: var(--medium-font-size);

    max-width: 20vw;

    margin-right: auto;
}

.entry .info{
    font-size: var(--medium-font-size);

    margin: 0.6vh auto;
    margin-left: 25vw;

    grid-column: 2;
}


#impressum .laws, #impressum .sources{
    margin: 0 10vw;
    margin-top: 14vh;
}

.laws .law{
    margin-top: 7vh;
}

.law .content, .sources .content{
    font-size: var(--medium-font-size);
    line-height: 150%;
    white-space: pre-wrap;

    margin-top: 2vh;
}



@media (max-width: 700px) {
    #impressum .laws, #impressum .sources{
        margin: 0 5vw;
        margin-top: 14vh;
    }
}

@media (max-width: 550px) {
    #impressum .report{
        margin: 5vh 5%;
    }

    .report .label{
        font-size: var(--medium-font-size);
    
        width: 20vw;
    
        margin-right: auto;
        margin-top: 0vh;

        float: left;
    }
    
    .report .info{
        font-size: var(--medium-font-size);
    
        margin: 1.5vh auto;
        margin-left: 25vw;
    
        grid-column: 2;
    }

    #impressum .laws, #impressum .sources{
        margin: 0;
        margin-top: 14vh;
    }
}