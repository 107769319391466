:root{
    --banner-bg: #F4F4F4;

    --banner-font-size: max(1vw, 12px);
}

.banner{
    background-color: var(--banner-bg);

    box-sizing: border-box;
    width: max(40vw, 450px);
    height: fit-content;

    padding: 10px 12px;
    padding-bottom: 7px;

    border-width: 2px;
    border-color: var(--layout-color);
    border-style: solid;
    border-radius: 10px 10px 10px 2px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    position: fixed;
    bottom: 35px;
    left: 25px;
}

.banner .text{
    font-size: var(--banner-font-size);

    background-color: inherit;

    margin: 0;
}

.banner .buttons-shell{
    background-color: inherit;

    width: 100%;
    height: fit-content;
    
    display: flex;
    justify-content: flex-end;
}

.buttons-shell .button{
    font-size: max(0.9vw, 10px);
    color: black;
    
    opacity: 0.9;

    width: max(15%, 60px);
    height: fit-content;

    padding: 2px 0;
    margin-top: 5px;
    margin-right: 18px;
    
    border-width: 1px;
    border-style: solid;
    border-color: #000000;
    border-radius: 100vw;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}
.button[type=accept]{
    /*background-color: rgba(82, 228, 89, 0.2);*/
    background-color: rgba(var(--layout-color-rgb), 0.4);
}
.button[type=configure]{
    background-color: rgba(7, 110, 194, 0);
}
.button[type=reject]{
    background-color: rgba(var(--title-bg-color-rgb), 0.4);
}


.configure-interface{
    margin: 1vh 0;
}

.configure-interface .setting{
    margin: max(0.3vw, 6px) 4vw;
    margin-right: 8vw;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.setting .title{
    font-size: var(--banner-font-size);

    margin: 0;
}



@media (max-width: 650px) {
    .banner{
        width: 80vw;
    }
}

@media (max-width: 500px) {
    .banner{
        width: calc(100vw - 40px);
    
        border-bottom-right-radius: 2px;

        bottom: 17px;
        left: 20px;
    }
}
