:root{
    --footer-height: min(max(max(7vh, 4.5vw), 50px), 65px);

    --footer-color: #cccccc;

    --footer-btn-color: rgb(122, 122, 122);
}

.footer{
    background-color: var(--footer-color);

    height: var(--footer-height);

    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    padding: 0 var(--nav-padding-x);

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.footer .btn-shell{
    text-decoration: none !important;
    
    background-color: inherit;

    height: 100%;

    margin: 0 1vw;

    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-shell .btn {
    font-size: var(--nav-font-size);
    line-height: 100%;

    color: black;
    background-color: inherit;

    height: max(min(35px, 90%), 65%);

    padding: 0 8px;
    border: 2px solid var(--footer-btn-color);
    border-radius: 5px;

    align-items: center;

    cursor: pointer;
}
