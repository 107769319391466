.zeitplan{
    display: flex;
}


.zeitplan table{
    font-size: var(--large-font-size);
    font-weight: 500;

    background-color: white;

    
    width: 100%;
    
    border-width: 3px;
    border-style: solid;
    border-color: var(--bg);
    border-collapse: collapse;

    shape-outside: margin-box;

    float: left;
}


.zeitplan td, th {
    font-size: var(--large-font-size);

    padding: 4px;
    background-color: inherit;
}

.zeitplan tr:nth-child(even) {
    background-color: rgba(var(--title-bg-color-rgb), 0.7);
}

tr td:first-child{
    width: 35%
}
tr td:nth-child(2){
    width: 25%
}
tr td:last-child{
    width: 40%
}