

#glaubensbekenntnis .category{
    border-width: 0px 2px 0px 2px; /* top right bottom left */
    border-style: none solid none solid;
    border-color: var(--layout-color);
    border-radius: 6px;

    width: 85%;

    padding: 4vh 2vw;

    margin: 9vh 0;
    margin-left: 3%;
}

#glaubensbekenntnis .category[edge="-1"]{
    border-width: 2px 2px 0px 2px; /* top right bottom left */
    border-style: solid solid none solid;
    border-color: var(--layout-color);
    border-radius: 6px;

    margin-top: 5vh;
}
#glaubensbekenntnis .category[edge="1"]{
    border-width: 0px 2px 2px 2px; /* top right bottom left */
    border-style: none solid solid solid;
    border-color: var(--layout-color);
    border-radius: 6px;

    margin-bottom: 5vh;
}

.category .title{
    font-size: max(1.8vw, 25px);

    background-color: var(--title-bg-color);

    min-width: fit-content;
    width: 40%;

    padding: 0.4vh max(1.5vw, 2vh);

    border-radius: 3px;

    margin-left: 15%;
    margin-top: 0;
}

.category .content{
    font-size: max(1.5vw, 18px);
    text-align: justify;
}

.category .verses-collection{
    padding: 3px 3px;
    margin-right: 22%;

    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
}

.verses-collection .verse{
    font-size: max(1.2vw, 15px);

    margin: 2px 10px;
}

@media (max-width: 550px){
    #glaubensbekenntnis .category{
        width: auto;
    
        padding: 2.5vh 2vw;
        margin: 6vh 0;
        margin-left: 0;
    }

    .category .title{
        width: 70%;
    
        margin-left: 5%;
        margin-top: 0;
    }

    .category .verses-collection{
        margin-right: 8%;
    }
    .verses-collection .verse{
        margin: 2px 8px;
    }
}

@media (max-width: 450px){
    .category .title{
        width: auto;
    
        margin-left: 0;
        margin-top: 0;
    } 

    .category .verses-collection{
        margin-right: 0;
    }
    .verses-collection .verse{
        margin: 2px 5px;
    }
}